import React from 'react';
import { graphql } from 'gatsby';
import * as Ink from '@carta/ink';
import Layout from '../components/Layout';
import StyledLink from '../components/Common/StyledLink';

import Header from '../components/Header/Header';
import { CodeWithAllStyles } from '../components/Common/PropDisplay';

const GettingStarted = () => {
  return (
    <Layout pageTitle="Getting started">
      <Header
        title="Getting Started"
        subtitle="
          ink is Carta’s design system. It allows Carta to build product UI with great user experience
          at scale. Today, it manifests itself as a collection of React-based UI components infused with the visual and
          interaction design principles we hold most dear."
      />
      <Ink.Row>
        <Ink.Column mobile="12-of-12" tabletLandscape="8-of-12">
          <Ink.Box bottom="16">
            <Ink.Heading variant="heading-2">How can I get help with ink?</Ink.Heading>
            <Ink.Text>
              During working hours, our team is on call for design and engineering support at the{' '}
              <Ink.Anchor href="https://grid-carta.enterprise.slack.com/archives/CBFKQSKTN/">#ink channel</Ink.Anchor>{' '}
              on Slack. Make sure to include full-screen screenshots of what you're building or provide a test case in{' '}
              <Ink.Anchor href="https://ink.carta.com/playroom/">Playroom</Ink.Anchor> with your support requests. This
              helps us help you.
            </Ink.Text>
          </Ink.Box>
          <Ink.Box bottom="16">
            <Ink.Heading variant="heading-2">How can I contribute to ink?</Ink.Heading>
            <Ink.Text>
              ink welcomes external contributions. To understand how you can contribute to ink in many ways, go to{' '}
              <StyledLink to="/contributing">How to contribute to ink</StyledLink>. As a designer, attend the weekly{' '}
              <strong>ink Design Jam</strong> to propose new or updated components.
            </Ink.Text>
          </Ink.Box>
          <Ink.Box bottom="16">
            <Ink.Heading variant="heading-2">How do I design with ink?</Ink.Heading>
            <Ink.Text>
              ink provides code and no-code design tooling for working with ink. We encourage designers to work within
              the components' constraints as much as possible because constraints allow us to build a system that
              scales.
            </Ink.Text>
            <Ink.Ul>
              <Ink.Li>
                <StyledLink to="/guides/101-playroom">Playroom 101</StyledLink>
              </Ink.Li>
              <Ink.Li>
                <StyledLink to="/guides/101-figma-library">Figma library 101</StyledLink>
              </Ink.Li>
            </Ink.Ul>
          </Ink.Box>
          <Ink.Box bottom="16">
            <Ink.Heading variant="heading-2">How do I code with ink?</Ink.Heading>
            <Ink.Text>
              You can use ink inside <CodeWithAllStyles>carta-web</CodeWithAllStyles> or in a repo outside of{' '}
              <CodeWithAllStyles>carta-web</CodeWithAllStyles>.
            </Ink.Text>
            <Ink.Ul>
              <Ink.Li>
                <Ink.Anchor href="https://github.com/carta/ink/wiki/How-to-use-Ink-in-projects-outside-of-Carta-Web">
                  Using ink in projects outside of carta-web
                </Ink.Anchor>
              </Ink.Li>
            </Ink.Ul>
          </Ink.Box>
        </Ink.Column>
      </Ink.Row>
    </Layout>
  );
};

export default GettingStarted;

export const query = graphql`
  query GettingStartedQuery {
    allContributing: allMdx(filter: { fileAbsolutePath: { regex: "/content/getting-started/" } }) {
      nodes {
        frontmatter {
          title
        }
        slug
      }
    }
  }
`;
